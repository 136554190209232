<template>
  <component
    :is="layerControlUse"
    v-if="layerControlUse"
    v-bind="$attrs"
    v-on="$listeners"
    ref="layer"
  >
    <slot />
  </component>
</template>

<script>
import ModuleMixin from "@components/map-container/mixins/ModuleMixin";
import { polygon, booleanPointInPolygon } from "@turf/turf";

export default {
  mixins: [ModuleMixin],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    MapboxLayerControl: () =>
      import(
        /* webpackChunkName: "mapbox-fullapp" */ "../../mapbox/modules/LayerControl/LayerControl.vue"
      ),
    LeafletLayerControl: () =>
      import(
        /* webpackChunkName: "leaflet-fullapp" */ "../../leaflet/modules/LayerControl/LayerControl.vue"
      ),
  },
  computed: {
    layerControlUse() {
      if (this.isMapbox) return "MapboxLayerControl";
      if (this.isLeaflet) return "LeafletLayerControl";
      return "";
    },
  },
  methods: {
    onSetDataSearch(...params) {
      return this.$refs.layer.onSetDataSearch(...params);
    },
    flyTo(...params) {
      return this.$refs.layer.flyTo(...params);
    },
    onMarket(...params) {
      return this.$refs.layer.onMarket(...params);
    },
    onRemoveMarket(...params) {
      return this.$refs.layer.onRemoveMarket(...params);
    },
    onMarketItems(...params) {
      return this.$refs.layer.onMarketItems(...params);
    },
    onShowPopup(...params) {
      return this.$refs.layer.onShowPopup(...params);
    },
    checkPointInCurrentView(point) {
      if (!this.map) return;
      const bounds = this.map.getBounds();
      const boundsGeometry = polygon([
        [
          [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
          [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
          [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
          [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
          [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
        ],
      ]);
      return booleanPointInPolygon(point, boundsGeometry);
    },
    resize(...params) {
      return this.$refs.layer.resize(...params);
    },
    onRemovePopup(...params) {
      return this.$refs.layer.onRemovePopup(...params);
    },
    onShowData(...params) {
      return this.$refs.layer.onShowData(...params);
    },
  },
};
</script>
