import { AMap } from "./Amap";

export class MapboxClass extends AMap {
  getMap() {
    return this._map;
  }

  zoomIn(e) {
    this._map.zoomIn({}, { originalEvent: e });
  }
  zoomOut(e) {
    this._map.zoomOut({}, { originalEvent: e });
  }

  addControl(control, position) {
    this._map.addControl(control, position);
  }
  removeControl(control) {
    this._map.removeControl(control);
  }
  getContainer() {
    return this._map.getContainer();
  }
  resize() {
    return this._map.resize();
  }
  flyTo(center, zoom = 12) {
    if (!this._map) return;
    this._map.flyTo({ center, zoom, duration: 0 });
  }
}
