<template>
  <ModuleContainer>
    <slot />
  </ModuleContainer>
</template>

<script>
import ModuleMixin from "@components/map-container/mixins/ModuleMixin";
import { debounce } from "@utils";

export default {
  mixins: [ModuleMixin],

  data: () => ({
    zoom: null,
    bbox: [],
  }),
  methods: {
    onInit() {
      this.bindedOnChange = this.onZoom.bind(this);
      this.map.on("zoom", this.bindedOnChange);
      this.map.on("move", this.bindedOnChange);
    },
    onDestroy() {
      this.map.off("zoom", this.bindedOnChange);
      this.map.off("move", this.bindedOnChange);
      this.bindedOnChange = null;
    },
    onZoom: debounce(function (e) {
      var bounds = this.map.getBounds();
      var zoom = this.map.getZoom();
      let zoomRounded = round(zoom, 2);
      let center = this.map.getCenter();
      let bboxRounded = [
        round(bounds.getWest()),
        round(bounds.getSouth()),
        round(bounds.getEast()),
        round(bounds.getNorth()),
      ];
      if (
        this.zoom != zoomRounded ||
        this.bbox.toString() != bboxRounded.toString()
      ) {
        this.bbox = bboxRounded;
        this.zoom = zoomRounded;
        this.$emit("change", {
          zoom: this.zoom,
          bbox: this.bbox,
          center,
        });
      }
    }, 200),
  },
};

function round(num, fixed = 4) {
  return (
    Math.round((num + Number.EPSILON) * Math.pow(10, fixed)) /
    Math.pow(10, fixed)
  );
}
</script>
