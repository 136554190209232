import { getUUIDv4 } from "@/utils";

export class ButtonControl {
  /**
   *
   * @param {string} title
   * @param {string} icon Material Design Icon Name https://materialdesignicons.com
   * @param {string} className additional class name
   */
  constructor(title, icon, className, onClick) {
    this.title = title;
    this.icon = icon;
    this.className = className;
    this.onClick = onClick;
    this.id = getUUIDv4();
  }

  onAdd(map) {
    this._map = map;

    // Icon
    const icon = document.createElement("i");
    icon.className = this.icon;
    icon.ariaHidden = true;
    icon.style.fontSize = "18px";

    // Button
    this._container = document.createElement("button");
    this._container.className = this.className || "";
    this._container.type = "button";
    this._container.title = this.title;
    this._container.ariaLabel = this.title;
    this._container.ariaPressed = false;

    this._container.onclick = (e) => {
      if (this.onClick instanceof Function) {
        this.onClick(e);
      }
    };

    this._container.appendChild(icon);

    return this._container;
  }

  onRemove() {
    if (!this._container || !this._container.parentNode) return;
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}
