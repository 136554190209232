import { ButtonControl } from "../../control/ButtonControl";

export class CustomGeolocaleButton extends ButtonControl {
  constructor(vue) {
    super();
    this.vue = vue;
    this.onClick = () => {
      vue.onClick();
    };
  }
  onAdd(map) {
    if (this.vue.onInit instanceof Function) {
      this.vue.onInit(map);
    }
    return this.vue.$el;
  }
  onRemove(map) {
    if (this.vue) {
      if (this.vue.onDestroy instanceof Function) {
        this.vue.onDestroy(map);
      }
      this.vue.$destroy();
    }
  }
}
