<template>
  <div class="mapboxgl-ctrl-bottom-center image-logo">
    <img :src="url || '/images/logo-fullname-transparent.png'" alt="logo" />
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
  },
  methods: {},
};
</script>
<style>
.mapboxgl-ctrl-bottom-center {
  bottom: 10px;
  left: 50%;
  position: absolute;
  pointer-events: none;
  z-index: 1;
  opacity: 1;
  transform: translateX(-50%);
  z-index: 900;
}
.image-logo img {
  width: 15vw;
  max-width: 150px;
  height: 100%;
}
</style>
