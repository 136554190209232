import { getUUIDv4 } from "@/utils";

import { ButtonControl } from "./ButtonControl";

export class ButtonGroupControl {
  /**
   *
   * @param {ButtonControl[]} buttons
   */
  constructor(buttons) {
    this.buttons = buttons || [];
    this.id = getUUIDv4();
  }

  /**
   *
   * @param {object[]} buttons
   * @param {string} buttons.title
   * @param {string} buttons.icon mdi icon, only input name. E.g. home
   * @param {string} [buttons.className]
   * @param {() => void} buttons.onClick
   */
  static create(buttons) {
    return new ButtonGroupControl(
      buttons.map(
        (b) => new ButtonControl(b.title, b.icon, b.className, b.onClick)
      )
    );
  }

  onAdd(map) {
    this._map = map;
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

    this.attachCurrentButtons();

    return this._container;
  }

  onRemove() {
    if (this._container && this._container.parentNode)
      this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }

  /**
   *
   * @param {ButtonControl} button
   */
  addButton(button) {
    this.buttons.push(button);

    this.attachButton(button);
  }

  /**
   * @private
   */
  attachCurrentButtons() {
    this.buttons.forEach((button) => this.attachButton(button));
  }

  /**
   *
   * @param {ButtonControl} button
   */
  attachButton(button) {
    if (!this._container) return;

    this._container.appendChild(button.onAdd(this._map));
  }
}
