<template>
  <ModuleContainer>
    <slot />
  </ModuleContainer>
</template>

<script>
import ModuleMixin from "@components/map-container/mixins/ModuleMixin";
import { BaseMapButton } from "./BaseMapButton.js";
import { createBaseMapHandle } from "./handle";
import i18n from "@/plugins/i18n";
export default {
  mixins: [ModuleMixin],
  props: {
    disabelShowBtn: Boolean,
    defaultBaseMap: {
      type: String,
      default: "Mặc định",
    },
    title: { type: String, default: i18n.t("map.BaseMapControl.Title") },
  },
  data: () => ({
    p_baseMaps: window.config.base_map || [
      {
        title: "Mặc định",
        type: "raster",
        thumbnail: require("./map-light.png"),
        links: ["https://tiles.skymapglobal.vn/styles/basic/{z}/{x}/{y}.png"],
        minzoom: 0,
        maxzoom: 20,
        bounds: [99.1383562, 6.2135213, 110.337243, 23.5054523],
      },
      {
        title: "Nền tối",
        type: "raster",
        thumbnail: require("./map-dark.png"),
        links: ["https://tiles.skymapglobal.vn/styles/dark/{z}/{x}/{y}.png"],
        minzoom: 0,
        maxzoom: 20,
        bounds: [99.1383562, 6.2135213, 110.337243, 23.5054523],
      },
    ],
  }),

  methods: {
    onInit() {
      this.handler = createBaseMapHandle(this.mapUse, this.map);
      if (!this.disabelShowBtn)
        this.addControl(
          new BaseMapButton({
            title: this.title,
            baseMaps: this.p_baseMaps,
            defaultIndexBaseMap: this.p_baseMaps.findIndex(
              (b) => b.title === this.defaultBaseMap
            ),
            onClick: (basemap) => {
              this.handler.loadItem(basemap);
            },
          })
        );
      this.handler.loadItem(
        this.p_baseMaps.find((b) => b.title === this.defaultBaseMap) ||
          this.p_baseMaps[0]
      );
    },
    onDestroy() {
      this.handler.clear();
      this.handler = null;
    },
  },
};
</script>
