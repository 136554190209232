import { AMap } from "./Amap";
import L from "leaflet";

export class LeafletClass extends AMap {
  constructor(
    map,
    { center = [105.85121154785156, 21.02682813712425], zoom = 8 } = {}
  ) {
    super(map);
    this._controls = {};
    this._map.setView(L.latLng(center[1], center[0]));
    this._map.setZoom(zoom);
  }
  getMap() {
    return this._map;
  }
  zoomIn(e) {
    if (this._map._zoom < this._map.getMaxZoom()) {
      this._map.zoomIn(this._map.options.zoomDelta);
    }
  }
  zoomOut(e) {
    if (this._map._zoom > this._map.getMinZoom()) {
      this._map.zoomOut(this._map.options.zoomDelta);
    }
  }
  addControl(control, position) {
    let temp = L.Control.extend({
      onAdd: control.onAdd.bind(control),
      onRemove: control.onRemove.bind(control),
    });
    temp = new temp({ position: position.replace("-", "") });
    this._map.addControl(temp);
    this._controls[control.id] = temp;
  }
  removeControl(control) {
    this._map.removeControl(this._controls[control.id]);
    delete this._controls[control.id];
  }
  getContainer() {
    return this._map.getContainer();
  }
  resize() {
    return this._map.invalidateSize();
  }

  getCenter() {
    return this._map.getCenter();
  }
  flyTo(center, zoom = 12) {
    if (!this._map) return;
    center = L.latLng(center[1], center[0]);
    this._map.setView(center, zoom, { animation: false });
  }
}
