<template>
  <div class="full-height full-width">
    <Map
      :initOptions="initOptions"
      class="flex-grow-1 full-height full-width"
      ref="map"
    >
      <Logo />
      <BaseMapControl
        position="bottom-left"
        :disabelShowBtn="!option.isShowTool"
      />
      <GeolocateControl position="bottom-right" />
      <ZoomControl position="bottom-right" v-if="option.isShowTool" />
      <FullScreenControl />
      <TrackingControl @change="onChangeZoom" />
    </Map>
  </div>
</template>
<script>
import {
  Map,
  ZoomControl,
  FullScreenControl,
  BaseMapControl,
  Logo,
  TrackingControl,
  GeolocateControl,
} from "@components/map-container";
import { debounce } from "@utils";
import { UrlParams } from "@service/model/UrlParams";
export default {
  props: { initOptions: {}, option: {} },
  components: {
    Map,
    ZoomControl,
    FullScreenControl,
    BaseMapControl,
    Logo,
    TrackingControl,
    GeolocateControl,
  },
  data: () => ({}),
  created() {
    this.params = new UrlParams();
    this.params.initData(window.location.search.substr(1)).then(() => {
      this.onHandleParams();
    });
  },
  methods: {
    async onHandleParams() {
      this.$nextTick(() => {
        if (this.$refs.map)
          this.$refs.map.flyTo(this.params.center, this.params.zoom);
      });
    },
    onChangeZoom({ zoom, bbox, center }) {
      if (center) {
        this.params.center = center;
      }
      if (zoom) {
        this.params.zoom = zoom;
      }
      this.bbox = bbox;
      this.onUpdateQueryUrl();
    },
    onUpdateQueryUrl: debounce(function (params) {
      if (this.searchResult && this.searchResult.id) {
        this.params.data = { search_result_id: this.searchResult.id };
      } else {
        this.params.data = null;
      }
      this.params.updateQueryUrl(params);
    }, 1000),
  },
};
</script>

<style></style>
