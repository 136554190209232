<template>
  <div
    class="d-flex full-height"
    :class="{
      'is-show-result': !!searchResult,
      'is-search-full-search': isSearch,
      'is-search-full-search':
        isSearch && ((isMobile && !searchResult) || !isMobile),
      'is-not-show-search': !option.isShowSearch,
      'is-show-search': option.isShowSearch,
      'is-show-nearby': !isMobile && !!searchResult && itemsNearby.length > 0,
      'is-mobile': isMobile,
    }"
  >
    <SearchControl
      class="search-container flex-grow-0"
      :search-result="searchResult"
      @update:searchResult="onSelectSearch"
      :loading.sync="loading"
      @click:reset="onResetSearch()"
      :isSearch.sync="isSearch"
      ref="SearchControl"
      @clear:searchResult="searchResult = null"
      v-if="option.isShowSearch && items.length > 0"
    >
    </SearchControl>

    <BoxSearchResult
      v-if="option.isShowBoxResult"
      ref="BoxSearchResult"
      @click:reset="onResetSearch()"
      class="box-result-container"
    />
    <div class="flex-grow-1 d-flex flex-column">
      <Map
        @map-loaded="onMapLoaded"
        @map-error="onMapError"
        :initOptions="initOptions"
        class="flex-grow-1 full-height full-width"
        :class="{
          'push-icon-mapbox': isMobile && !!searchResult,
        }"
      >
        <Logo :url="urlLogo" />
        <BaseMapControl
          :disabelShowBtn="!option.isShowTool || isMobile"
          position="bottom-left"
        />
        <GeolocateControl
          @update:location="onUpdateLocation"
          @update:region="onUpdateRegion"
          position="bottom-right"
          ref="location"
          v-if="option.isShowTool && !isMobile"
        />
        <ZoomControl
          v-if="option.isShowTool && !isMobile"
          position="bottom-right"
        />
        <LayerControl
          @click:layer="onClickLayer"
          ref="LayerControl"
          v-if="items.length > 0"
          :isComplexSearch="isComplexSearch"
          @update:location="onLayerUpdateLocation"
        />
        <TrackingControl @change="onChangeZoom" />
      </Map>
      <v-card
        class="flex-grow-0 position-relative silder-group flex-shrink-0"
        flat
        tile
        v-if="
          !isMobile && !!searchResult && itemsNearby && itemsNearby.length > 0
        "
      >
        <div class="silder-group-container d-flex flex-column">
          <a
            v-if="$refs.SearchControl"
            class="px-4 text-caption d-block flex-grow-0"
            @click="$refs.SearchControl.onBackToComplexSearch()"
          >
            {{ $t("map.SearchControl.BackToSearch") }}
          </a>
          <SearchSliderGroup
            class="flex-grow-1"
            :simple="isMobile"
            :items="itemsNearby"
            @click:select="onSelectSearch"
            :currentItem="searchResult"
          />
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { debounce } from "@/utils/Debounce";
import { UrlParams } from "@service/model/UrlParams";
import {
  Map,
  BaseMapControl,
  ZoomControl,
  LayerControl,
  TrackingControl,
  Logo,
  GeolocateControl,
} from "@components/map-container";
import SearchResultPopup from "@/views/SearchResultPopup.vue";
import Vue from "vue";
import { API_LAYER, API_MAP, API_PLACE } from "@service/api";
import { loadingContainer } from "@service/loading";
import HistorySearch from "@service/model/HistorySearch";
import { Place } from "@service/model/Place";
import { mapGetters } from "vuex";
export default {
  props: { initOptions: {}, option: {} },
  components: {
    Logo,
    Map,
    GeolocateControl,
    BaseMapControl,
    ZoomControl,
    LayerControl,
    TrackingControl,
    SearchControl: () =>
      import(/* webpackChunkName: "fullapp" */ "@/views/SearchControl.vue"),
    SearchSliderGroup: () =>
      import(
        /* webpackChunkName: "fullapp" */ "@/views/Result/SearchSliderGroup.vue"
      ),
    BoxSearchResult: () =>
      import(/* webpackChunkName: "boxapp" */ "@/views/BoxSearchResult.vue"),
  },
  async created() {
    loadingContainer.addContainer("global", (loading) => {
      this.loadingBar = loading;
    });
    this.params = new UrlParams();
    await this.params.initData(window.location.search.substr(1));

    this.onHandleParams();
    if (!this.params.key) {
      this.error.isMissingKey = true;
      return;
    }
    HistorySearch.setKey(this.params.key);
    this.fetchInitData();
    // this.fetchData();
    this.$root.$on("show:complex-search", ({ items, options, bbox, count }) => {
      this.$nextTick(() => {
        if (this.$refs.LayerControl) {
          this.$refs.LayerControl.onSetDataSearch(
            items,
            {
              fitBounds: options.fillBound,
              bbox,
              padding: !this.isMobile
                ? { top: 50, bottom: 50, left: 450, right: 50 }
                : { top: 50, bottom: 50, left: 50, right: 50 },
            },
            { count }
          );
        }
      });
    });
    this.$root.$on("hover:complex-search", (e) => {
      this.onHoverItem(e);
    });
    this.$root.$on("hover:complex-search-items", (e) => {
      this.onHoverItems(e);
    });
    this.$root.$on("fly-to:complex-search-items", (center) => {
      this.$refs.LayerControl.flyTo(center, 15);
    });
  },
  data: () => ({
    loadingBar: false,
    map: null,
    searchResult: null,
    loading: false,
    error: { isMissingKey: false, isWrongKey: false, isError: false },
    params: {},
    itemsNearby: [],
    isSearch: false,
    isComplexSearch: false,
    cachePlace: null,
  }),
  computed: {
    ...mapGetters({
      urlLogo: "map/urlLogo",
      items: "map/items",
    }),
    showOverlay() {
      return this.isError;
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    isError() {
      return Object.values(this.error).some((x) => x);
    },
    isDisableGetData() {
      return (
        this.isSearch ||
        this.isComplexSearch ||
        (this.itemsNearby && this.itemsNearby.length > 0)
      );
    },
  },
  methods: {
    onUpdateRegion(region) {
      const { state, district, subDistrict: commune } = region || {};
      this.$store.commit("search/setParams", { state });
    },
    onHoverItem(item) {
      if (!this.$refs.LayerControl) return;
      if (item) {
        this.$refs.LayerControl.onMarket([item.lng, item.lat]);
      } else {
        this.$refs.LayerControl.onRemoveMarket();
      }
    },
    onHoverItems(items) {
      if (!this.$refs.LayerControl) return;

      this.$refs.LayerControl.onMarketItems(items);
    },
    async fetchInitData() {
      const promises = [API_MAP()];
      try {
        const data = await Promise.all(promises);
        this.$store.commit("map/setPlace", data[0]);
      } catch (error) {
        console.error(error);
        const { response } = error;
        if (response) {
          const { status, data } = response;
          if (data && data.error && data.error.message === "Invalid key") {
            this.error.isWrongKey = true;
          } else {
            this.error.isError = true;
          }
        }
      }
    },
    fetchData: debounce(function (params = {}) {
      if (this.cachePlace) {
        return;
      }
      if (this.isDisableGetData) return;
      loadingContainer.addPending("get-places", "global");
      return API_LAYER(params)
        .then((res) => {
          this.cachePlace = res;
          this.$store.commit("map/setItems", res);
        })
        .finally(() => {
          loadingContainer.removePending("get-places");
        });
    }, 50),
    onSetSearchResult(result) {
      if (!this.option.isShowPopup && !this.option.isShowBoxResult) {
        this.searchResult = result ? new Place(result) : undefined;

        if (result) HistorySearch.addSearch({ type: "result", data: result });
      } else {
        this.searchResult = null;
      }
    },
    async onHandleParams() {
      if (this.params.data) {
        try {
          let data = this.params.data;
          if (data.search_result_id) {
            this.loading = true;
            API_PLACE({ id: data.search_result_id })
              .then((res) => {
                this.onSelectSearch(res);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        } catch (error) {
          console.error(error);
        }
      }
      this.$nextTick(() => {
        if (this.$refs.LayerControl) {
          this.$refs.LayerControl.flyTo(this.params.center, this.params.zoom);
        }
      });
    },
    onLayerUpdateLocation(location) {
      this.$refs.location.onGetLocation();
      this.onUpdateLocation(location);
    },
    onUpdateLocation(location) {
      this.$store.commit("search/setLocation", { location });
      if (this.$refs.SearchControl)
        this.$refs.SearchControl.onComplexSearch(true);
      if (this.params.isNoCenterInit) {
        if (this.$refs.LayerControl)
          this.$refs.LayerControl.flyTo(location, this.params.zoom);
      }
    },
    onMapLoaded(map) {
      this.fetchData();
    },
    onClickLayer(feature) {
      let searchResult = feature.properties.data;
      if (searchResult && typeof searchResult == "string")
        searchResult = JSON.parse(searchResult);
      this.onSetSearchResult(searchResult);
      this.onShowSearchResult(searchResult, true);
    },
    onOpenPopup(searchResult) {
      const content = new Vue(SearchResultPopup);
      content.$mount();
      content.setData({
        ...searchResult,
      });
      this.$refs.LayerControl.onShowPopup(
        [searchResult.lng, searchResult.lat],
        content.$el
      );
    },
    onShowSearchResult(searchResult, isFlyTo = false) {
      if (!searchResult) {
        this.searchResult = null;
        return;
      }
      this.onUpdateQueryUrl();
      if (this.$refs.LayerControl) {
        if (
          isFlyTo ||
          !this.$refs.LayerControl.checkPointInCurrentView([
            searchResult.lng,
            searchResult.lat,
          ])
        ) {
          this.$refs.LayerControl.flyTo(
            [searchResult.lng, searchResult.lat],
            14,
            !this.isMobile
              ? { top: 50, bottom: 50, left: 450, right: 50 }
              : { top: 50, bottom: 50, left: 50, right: 50 }
          );
        }
        if (this.option.isShowMarket) {
          this.$refs.LayerControl.onMarket([
            searchResult.lng,
            searchResult.lat,
          ]);
        }
      }

      if (this.option.isShowPopup) {
        this.onOpenPopup(searchResult);
      }
      if (this.option.isShowBoxResult) {
        this.$refs.BoxSearchResult.open(searchResult);
      }

      if (this.option.isShowSearch) {
        if (this.$refs.SearchControl)
          this.itemsNearby = this.$refs.SearchControl.getItemSearch();
      }
    },
    onSelectSearch(searchResult) {
      if (!searchResult && !this.isComplexSearch) {
        this.itemsNearby = [];
      }
      this.onSetSearchResult(searchResult);
      this.onShowSearchResult(searchResult, true);
    },
    onChangeZoom({ zoom, bbox, center }) {
      // if (!this.isComplexSearch) {
      //   this.fetchData({ zoom, bbox: bbox.toString() });
      // }
      if (center) {
        this.params.center = center;
      }
      if (zoom) {
        this.params.zoom = zoom;
      }
      this.$store.commit("search/setParams", { bbox });
      this.onUpdateQueryUrl();
    },
    onResetSearch() {
      this.searchResult = null;
      this.isSearch = false;
      this.itemsNearby = [];
      this.isComplexSearch = false;
      if (this.option.isShowMarket) {
        this.$refs.LayerControl.onRemoveMarket();
      }
      if (this.option.isShowBoxResult) {
        this.$refs.BoxSearchResult.close();
      }
      if (this.option.isShowPopup) {
        this.$refs.LayerControl.onRemovePopup();
      }
      this.$nextTick(() => {
        this.$refs.LayerControl.resize();
      });
      this.$refs.LayerControl.onSetDataSearch([]);
      this.$refs.LayerControl.onShowData();
      this.onUpdateQueryUrl();
    },
    onUpdateQueryUrl: debounce(function (params) {
      if (this.searchResult && this.searchResult.id) {
        this.params.data = { search_result_id: this.searchResult.id };
      } else {
        this.params.data = null;
      }
      this.params.updateQueryUrl(params);
    }, 1000),
    onMapError(e) {
      console.log({ ...e });
    },
  },
};
</script>
<style>
@media only screen and (min-width: 600px) and (max-width: 1264px) {
  .search-container {
    width: 320px;
  }
  .search-container .image-search-container {
    height: 200px;
  }
  .silder-group-container {
    left: 320px;
  }
  .is-show-result:not(.is-mobile) .mapboxgl-ctrl-bottom-left,
  .is-show-result:not(.is-mobile) .layer-container,
  .is-search-full-search:not(.is-mobile) .layer-container,
  .is-search-full-search:not(.is-mobile) .mapboxgl-ctrl-bottom-left {
    left: 330px;
  }
  .is-show-result:not(.is-mobile) .mapboxgl-ctrl-bottom-center,
  .is-search-full-search:not(.is-mobile) .mapboxgl-ctrl-bottom-center {
    padding-left: 330px;
  }
  .layer-container {
    width: 320px;
  }
  .scroll-area {
    width: 320px;
  }
}
@media only screen and (min-width: 1264px) {
  .search-container {
    width: 400px;
  }
  .search-container .image-search-container {
    height: 250px;
  }
  .silder-group-container {
    left: 400px;
  }
  .is-show-result:not(.is-mobile) .mapboxgl-ctrl-bottom-left,
  .is-show-result:not(.is-mobile) .layer-container,
  .is-search-full-search:not(.is-mobile) .layer-container,
  .is-search-full-search:not(.is-mobile) .mapboxgl-ctrl-bottom-left {
    left: 410px;
  }
  .is-show-result:not(.is-mobile) .mapboxgl-ctrl-bottom-center,
  .is-search-full-search:not(.is-mobile) .mapboxgl-ctrl-bottom-center {
    padding-left: 410px;
  }
  .layer-container {
    width: 400px;
  }
  .scroll-area {
    width: 400px;
  }
}
</style>
<style lang="scss" scoped>
.search-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  transition: max-height 1s;
}
.box-result-container {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 20;
}
.is-search-full-search {
  .search-container {
    height: 100%;
  }
}
.silder-group {
  height: 315px;
}
.silder-group-container {
  position: absolute;
  top: 12px;
  bottom: 12px;
  right: 0;
}
.silder-group-container {
  z-index: 1;
}
.is-show-result:not(.is-mobile) {
  height: 100vh;
}
</style>
<style>
.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-center,
.mapboxgl-ctrl-bottom-right,
.search-container,
.layer-container {
  transition: all 0.2s ease;
}
/* .is-show-nearby .mapboxgl-ctrl-bottom-right,
.is-show-nearby .mapboxgl-ctrl-bottom-left {
  bottom: 146px;
} */
.push-icon-mapbox .mapboxgl-ctrl-bottom-right,
.push-icon-mapbox .mapboxgl-ctrl-bottom-left,
.push-icon-mapbox .mapboxgl-ctrl-bottom-center {
  bottom: 40vh;
}
.layer-container {
  position: absolute;
  left: 0px;
  top: 112px;
}
.is-mobile .layer-container {
  top: 120px;
  left: 0;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  overflow: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.is-mobile .layer-container::-webkit-scrollbar {
  display: none;
}
.is-show-result .layer-container,
.is-not-show-search .layer-container,
.is-search-full-search .layer-container {
  top: 10px;
}
.is-show-result.is-mobile .layer-container {
  top: 70px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.scroll-area {
  position: relative;
  margin: auto;
  height: 100%;
}
</style>
