<template>
  <div
    class="popup-place-card"
    @mouseover="hover = true"
    @mouseleave="onClose()"
    @click="onOpenItem()"
  >
    <div class="image-container" v-if="item.background">
      <img
        :src="item.background"
        alt=""
        width="300px"
        height="150px"
        style="object-fit: cover"
      />
    </div>
    <div class="pa-4 pt-3 full-width d-flex">
      <div
        class="flex-grow-1 d-flex flex-column"
        style="width: calc(100% - 30px)"
      >
        <div class="text-subtitle-2 text-truncate clickable">
          {{ item.address||item.name }}
        </div>
        <div v-if="item.place" class="text-caption">
          {{ item.place.name }}
        </div>
        <div class="text-caption">
          <StatusTypeText :value="!item.isClosed" />
        </div>
      </div>
      <div class="flex-grow-0 px-1" style="font-size: 18px">
        <a
          :href="item.guideLink"
          target="_black"
          class="text-decoration-none d-block primary--text clickable"
          title="Đường đi"
        >
          <i class="mdi mdi-directions"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import StatusTypeText from "@/views/StatusTypeText.vue";
import { Place } from "@/service/model/Place";
export default {
  components: { StatusTypeText },
  data() {
    return { item: {}, hover: false };
  },
  computed: {},
  methods: {
    setData(item) {
      this.item = new Place(item);
    },
    check() {
      setTimeout(() => {
        if (!this.hover) {
          this.onClose();
        }
      }, 200);
    },
    onOpenItem() {
      this.$emit("click:item", this.item);
      this.onClose();
    },
    onClose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.popup-place-card {
  width: 300px;
  cursor: pointer;
}
</style>
