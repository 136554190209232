import L from "leaflet";
export class LeafletBasemap {
  constructor(map) {
    this.map = map;
    this.layer = null;
  }
  loadItem(item) {
    this.clear();
    this.getLoader(item.type)(item);
  }
  loadRaster(item) {
    this.layer = L.tileLayer(item.links[0], {
      minZoom: item.minzoom || 0,
      maxZoom: item.maxzoom || 16,
    });

    this.layer.addTo(this.map.getMap());
    this.layer.bringToBack();
  }
  loadVector(item) {}
  getLoader(type) {
    switch (type) {
      case "vector":
        return this.loadVector.bind(this);
      case "raster":
        return this.loadRaster.bind(this);
      default:
        throw new Error(`Not support ${type}`);
    }
  }
  clear() {
    if (!this.layer) {
      return;
    }
    this.layer.remove();
  }
}
