import { LeafletBasemap } from "./LeafletBasemap";
import { MapboxBasemap } from "./MapboxBasemap";

export function createBaseMapHandle(mapUse, map) {
  switch (mapUse) {
    case "MapboxMap":
      return new MapboxBasemap(map);
    case "LeafletMap":
      return new LeafletBasemap(map);
    default:
      throw new Error("Not support for map: " + mapUse);
  }
}
