<template>
  <div class="module__container" :class="classes">
    <div class="module-content__container">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    relative: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return { "module__container--relative": this.relative };
    },
  },
};
</script>

<style scoped>
.module__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 900; /* above popupPane of leaflet https://leafletjs.com/reference-1.6.0.html#map-popuppane */
}

.module-content__container {
  pointer-events: all;
}

.module__container--relative {
  flex: none;
  position: relative;
  height: auto;
  min-height: 0;
  pointer-events: all;
}
</style>
