export const BASEMAP_PREFIX = "$__base-map-control__";

import axios from "axios";
export class MapboxBasemap {
  constructor(map) {
    this.map = map.getMap();
    this.sourceIds = [];
    this.layerIds = [];
  }
  loadRaster(item) {
    if (!item) throw new Error("Not found item");

    const layerId = BASEMAP_PREFIX + item.title;

    this.map.addLayer(
      {
        id: layerId,
        type: "raster",
        source: {
          type: "raster",
          tiles: item.links,
          maxzoom: item.maxzoom || 22,
          minzoom: item.minzoom || 0,
          tileSize: item.tileSize || 256,
        },
      },
      this.getLowestLayerId()
    );

    this.layerIds.push(layerId);
  }

  async loadVector(item) {
    if (!this.map) return;
    const res = await axios(item.links[0]);

    // Add sources
    for (const id in res.data.sources) {
      const sourceId = BASEMAP_PREFIX + id;

      this.map.addSource(sourceId, res.data.sources[id]);
      this.sourceIds.push(sourceId);
    }

    // Add layers
    const lowestLayerId = this.getLowestLayerId();
    for (const layer of res.data.layers) {
      const layerId = BASEMAP_PREFIX + layer.id;
      const sourceId = BASEMAP_PREFIX + layer.source;

      this.map.addLayer(
        Object.assign(layer, { id: layerId, source: sourceId }),
        lowestLayerId
      );
      this.layerIds.push(layerId);
    }
  }
  getLowestLayerId() {
    const layers = this.map.getStyle().layers;

    return layers.length > 0 ? layers[0].id : null;
  }
  getLoader(type) {
    switch (type) {
      case "vector":
        return this.loadVector.bind(this);
      case "raster":
        return this.loadRaster.bind(this);
      default:
        throw new Error(`Not support ${type}`);
    }
  }
  loadItem(item) {
    this.clear();

    this.getLoader(item.type)(item);
  }
  clear() {
    if (!this.map) return;
    for (const layerId of this.layerIds) {
      if (this.map.getLayer(layerId)) {
        this.map.removeLayer(layerId);
      }

      if (this.map.getSource(layerId)) {
        this.map.removeSource(layerId);
      }
    }

    for (const sourceId of this.sourceIds) {
      if (this.map.getSource(sourceId)) {
        this.map.removeSource(sourceId);
      }
    }

    this.layerIds = [];
    this.sourceIds = [];
  }
}
