import storage from "@/service/storage.js";
const MAX_LENGTH = 10;
export class HistorySearch {
  searchs = [];
  constructor(key) {
    storage.getItem("history-search-" + this.key).then((res) => {
      this.searchs = res || [];
    });
  }
  setKey(key) {
    this.key = key;
    storage.getItem("history-search-" + key).then((res) => {
      this.searchs = res || [];
    });
  }
  addSearch(search) {
    if (search && !this.chechHasData(search)) {
      this.searchs.push(search);
      if (this.searchs.length > MAX_LENGTH) {
        this.searchs.shift();
      }
      this.updateLocalStorage();
    }
  }
  chechHasData(data) {
    if (data.type == "result") {
      return !!this.searchs.find(
        (x) =>
          x.type && x.type == "result" && x.data && x.data.id == data.data.id
      );
    } else return this.searchs.includes(data);
  }
  checkByString(item, search) {
    if (item.type == "result") {
      return (
        item.type &&
        item.type == "result" &&
        item.data &&
        item.data.name.includes(search)
      );
    } else return item.includes(search);
  }
  removeSearch(search) {
    this.searchs = this.searchs.filter((x) => x != search);
    this.updateLocalStorage();
  }
  clear() {
    this.searchs = [];
  }
  updateLocalStorage() {
    storage.setItem("history-search-" + this.key, this.searchs);
  }
}
const model = new HistorySearch();
export default model;
