<template>
  <component
    :is="mapUse"
    v-if="mapUse"
    v-bind="$attrs"
    v-on="$listeners"
    @map-loaded="onMapLoaded"
    :initOptions="initOptions"
    :gestureHandling="isMobile"
  >
    <slot />
  </component>
</template>

<script>
import mapboxgl from "mapbox-gl";
import { LeafletClass } from "./model/leaflet";
import { MapboxClass } from "./model/mapboxgl";
import { parseQueryString } from "@/utils";
let params = parseQueryString(window.location.search.substr(1));
const isMapboxSupport = mapboxgl.supported();
let mapUse = isMapboxSupport ? "MapboxMap" : "LeafletMap";
if (params.map == "leaflet") {
  mapUse = "LeafletMap";
}
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    MapboxMap: () =>
      import(/* webpackChunkName: "mapbox-fullapp" */ "./mapbox/Map.vue"),
    // eslint-disable-next-line vue/no-unused-components
    LeafletMap: () =>
      import(/* webpackChunkName: "leaflet-fullapp" */ "./leaflet/Map.vue"),
  },
  props: {
    useMap: {
      type: String,
    },
    initOptions: {
      type: Object,
      default: () => ({
        center: [105.85121154785156, 21.02682813712425],
        zoom: 7,
        maxZoom: 22,
        attributionControl: false,
        zoomControl: false,
      }),
    },
  },
  data: () => ({
    isMapboxSupport: true,
    mapUse: "",
    map: null,
    callBackWhenLoaded: [],
  }),
  provide() {
    return {
      getMap: this.getMap,
      mapUse: mapUse,
    };
  },
  created() {
    this.isMapboxSupport = isMapboxSupport;
    this.mapUse = mapUse;
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    onMapLoaded(map) {
      this.map = createMap(this.mapUse, map, this.initOptions);
      if (this.callBackWhenLoaded.length > 0) {
        this.callBackWhenLoaded.forEach((cb) => cb(this.map));
      }
    },
    getMap(callback) {
      if (this.map) {
        return callback(this.map);
      } else {
        this.callBackWhenLoaded.push(callback);
      }
    },
    flyTo(center, zoom) {
      if (!this.map) {
        this.callBackWhenLoaded.push((map) => {
          map.flyTo(center, zoom);
        });
        return;
      }
      this.map.flyTo(center, zoom);
    },
  },
};
export function createMap(type, ...params) {
  switch (type) {
    case "MapboxMap":
      return new MapboxClass(...params);
    case "LeafletMap":
      return new LeafletClass(...params);
  }
}
</script>

<style scoped></style>
