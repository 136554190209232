export class AMap {
  constructor(map) {
    this._map = map;
  }

  on(...params) {
    this._map.on(...params);
  }
  off(...params) {
    this._map.off(...params);
  }
  getCenter() {
    return this._map.getCenter();
  }
  getZoom() {
    return this._map.getZoom();
  }
  getBounds() {
    return this._map.getBounds();
  }
}
